<template>
  <AgenciesForm
    @save="createAgency"
    :agency="agency"
    :type="'create'"
  />
</template>

<script>
import beforeLeaveRouteModal from '@/mixins/beforeLeaveRouteModal.js'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  components: {
    AgenciesForm: () => import('./AgenciesForm'),
  },
  mixins: [beforeLeaveRouteModal, checkProfileRoutePermission],
  data: () => ({
    agency: { type: 'agency' },
    askToLeave: true,
  }),
  methods: {
    createAgency() {
      this.askToLeave = false
      this.agency.person_type = 'PJ'
      this.$api
        .post('people', this.agency)
        .then(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'Agência cadastrada com sucesso',
            confirm: () => {
              this.$router.push({ name: 'agencies' })
            },
          })
        })
        .catch(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops! Falha ao tentar cadastrar esta agências',
            caption: 'Verifique se os campos estão preenchidos corretamente.',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.askToLeave) {
      this.openModal(
        () => {
          next()
        }, // Caso o modal seja confirmado
        () => {
          next(false)
        } // Caso o modal seja negado
      )
    } else {
      next()
    }
  },
}
</script>
